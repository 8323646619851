<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <h5 class="text-primary desktop-view">
            <!-- Job {{ activityDetails.job_id }} : -->
            Job {{ activityDetails.job_display_uid }} :
            {{ activityDetails.job.job_title }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary font-weight-bold desktop-view">
              <span
                >Completed on :
                {{ activityDetails.completed_date | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div class="pb-3 mobile-view">
          <h5 class="text-primary mobile-view">
            <!-- Job {{ activityDetails.job_id }} :{{ activityDetails.job.job_title }} -->
            Job {{ activityDetails.job_display_uid }} :{{
              activityDetails.job.job_title
            }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Completed on :
                {{ activityDetails.completed_date | formatDate }}</span
              >
            </span>
          </div>
        </div>
        <div>
          <!-- <div class="d-flex">
            <h5 > Salary Range: </h5>
          </div> -->
          <div class="d-flex justify-content-between pb-2">
            <div class="d-flex">
              <span class="text-primary font-weight-bold">
                Salary Range: {{ salaryRange(activityDetails) || "--" }}</span
              >
            </div>
            <div>
              <CButton
                type="button"
                color="primary"
                class="mx-2 px-2 desktop-view"
                @click="openJobAdvert(activityDetails.job_id)"
                >Preview Advert</CButton
              >
              <CButton
                type="button"
                color="primary"
                class="mx-2 px-2 desktop-view"
                @click="navToJobDetails(activityDetails.job_id)"
                >Job Details</CButton
              >
            </div>
          </div>
          <div class="center mobile-view">
            <CButton
              type="button"
              color="primary"
              class="mx-1 py-1 mobile-view"
              @click="openJobAdvert(activityDetails.job_id)"
              >Preview Advert</CButton
            >
            <CButton
              type="button"
              color="primary"
              class="mx-1 py-1 mobile-view"
              @click="navToJobDetails(activityDetails.job_id)"
              >Job Details</CButton
            >
          </div>
          <div class="pt-2">
            <CRow>
              <CCol md="7">
                <CDataTable
                  :striped="true"
                  :bordered="true"
                  :small="true"
                  :items="activityComments"
                  :fields="fields"
                  pagination
                  :items-per-page="5"
                  class="p-2"
                >
                </CDataTable>
              </CCol>
              <CCol md="5"> </CCol>
            </CRow>
            <div class="text-center">
              <CButton
                type="button"
                color="primary"
                class="action-btn mx-1 my-2 p-2"
                variant="outline"
                shape="square"
                @click="navToActivityList"
                >Cancel</CButton
              >
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <JobCardPreviewModal
      :isShowPopup="advertModal.isShowPopup"
      :onCancelPreview="advertModalCallBack"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TextareaInput,
    JobCardPreviewModal,
  },
  data() {
    return {
      advertModal: {
        isShowPopup: false,
      },
      fields: [
        { key: "date", _style: "width:25%" },
        {
          key: "name",
          _style: "width:25%",
        },
        {
          key: "role",
          _style: "width:10%",
        },
        {
          key: "status",
          _style: "width:15%",
        },
        {
          key: "comment",
          _style: "width:25%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCustomerId", "getReviewedJobID", "getCurrency"]),

    activityComments() {
      return (
        this.comments
          // .filter((e) => e.comments)
          .map((v) => ({
            name: v.initiated_by_user_name || "--",
            date: this.formatDate(v.created_on) || "--",
            comment: v?.comments || "--",
            role:
              (v?.initiated_by_user?.roles &&
                v?.initiated_by_user?.roles
                  .map((v) => _.startCase(v.role_type))
                  .join()) ||
              "--",
            status:
              `${v?.status} ${v?.sub_status ? " - " + v?.sub_status : ""}` ||
              "--",
          })) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchActivity",
      "fetchJobBenefitsAdvertDescByJobId",
    ]),
    navToActivityList() {
      this.$store.commit("SET_ACTIVE_TAB", 1);
      this.$router.push({ path: `/list-activity` });
    },
    navToJobDetails(job_id) {
      // let fullPath = this.$route.fullPath;
      // this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      // let fullPath = this.$route.fullPath;
      // this.$router.push({ path: `/job-list-preview/${job_id}` });
      // let routeData = this.$router.resolve({
      //   path: `/job-list-preview/${job_id}`,
      // });
      // window.open(routeData.href, "_blank");
      this.$emit("openJobInfoModal", job_id);
    },
    openJobAdvert(job_id) {
      this.$store.commit("SET_REVIEWED_JOBID", job_id);
      this.fetchJobBenefitsAdvertDescByJobId({
        job_id,
        customer_id: this.getCustomerId,
      }).then((res) => {
        this.advertModal.isShowPopup = true;
      });
    },

    advertModalCallBack() {
      this.advertModal.isShowPopup = false;
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    salaryRange(data) {
      if (data?.job?.salary_min && data?.job?.salary_max)
        return `${data?.job?.salary_min_text || data?.job?.salary_min} - ${
          data?.job?.salary_max_text || data?.job?.salary_max
        } ${this.currencyName(data.job.salary_currency_id)}`;
      return null;
    },
    currencyName(currency_id) {
      if (currency_id)
        return _.result(
          _.find(this.getCurrency, { code: currency_id }),
          "label"
        );
      return "";
    },
  },
  filters: {
    formatDate(data) {
      if (data) return m(data).format("Do MMM YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
.display-block {
  display: block;
}
.center {
  display: flex;
  justify-content: center;
}
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>