<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <h5 class="text-primary desktop-view">
            <!-- Job {{ activityDetails.job_id }} : -->
            Job {{ activityDetails.job_display_uid }} :
            {{ activityDetails.job.job_title }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary font-weight-bold desktop-view">
              <span
                >Created : {{ activityDetails.created_on | formatDate }}</span
              >
              <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left : </span
                >{{ activityDetails.display_text_days }}
              </span>
              <!-- <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left : </span
                >{{ activityDetails.expiry_date | remainingDays }}
              </span> -->
            </span>
          </div>
        </div>
        <div class="pb-3 mobile-view">
          <h5 class="text-primary mobile-view">
            <!-- Job {{ activityDetails.job_id }} : -->
            Job {{ activityDetails.job_display_uid }} :
            {{ activityDetails.job.job_title }}
          </h5>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Created : {{ activityDetails.created_on | formatDate }}</span
              >
              <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left : </span
                >{{ activityDetails.display_text_days }}
              </span>
              <!-- <span class="d-block"
                ><span class="font-weight-lighter pr-1">Days Left : </span
                >{{ activityDetails.expiry_date | remainingDays }}
              </span> -->
            </span>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between pb-2">
            <div class="d-flex">
              <span class="text-primary font-weight-bold">
                Salary Range: {{ salaryRange(activityDetails) || "--" }}</span
              >
            </div>
            <div>
              <CButton
                name="preview-advert-btn"
                type="button"
                class="mx-2 px-2 desktop-view btn-primary"
                @click="openJobAdvert(activityDetails.job_id)"
                >Preview Advert</CButton
              >
              <CButton
                name="job-details-btn"
                type="button"
                class="mx-2 px-2 desktop-view btn-primary"
                @click="navToJobDetails(activityDetails.job_id)"
                >Job Details</CButton
              >
            </div>
          </div>
          <div class="center mobile-view">
            <CButton
              name="preview-advert-btn"
              type="button"
              class="mx-1 py-1 mobile-view btn-primary"
              @click="openJobAdvert(activityDetails.job_id)"
              >Preview Advert</CButton
            >
            <CButton
              name="job-details-btn"
              type="button"
              class="mx-1 py-1 mobile-view btn-primary"
              @click="navToJobDetails(activityDetails.job_id)"
              >Job Details</CButton
            >
          </div>
          <div class="pt-2">
            <CRow>
              <CCol md="7">
                <CDataTable
                  :striped="true"
                  :bordered="true"
                  :small="true"
                  :items="activityComments"
                  :fields="fields"
                  pagination
                  :items-per-page="5"
                  class="p-2"
                >
                </CDataTable>
              </CCol>
              <CCol md="5">
                <ValidationObserver
                  ref="reviewCandidate"
                  v-slot="{ handleSubmit }"
                >
                  <form
                    id="reviewCandidate"
                    @submit.prevent="handleSubmit(onSubmit)"
                  >
                    <CRow class="pb-3">
                      <label
                        name="action_label"
                        class="required col-lg-12 col-md-12"
                        >Select Action</label
                      >
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="status_id"
                            :value="review.status_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['actions']
                                ? options['actions']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <CRow class="pb-3">
                      <label
                        class="col-lg-12 col-md-12"
                        :class="{ required: isApproved }"
                        >Comment</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          :rules="{ required: isApproved }"
                          v-slot="{ errors }"
                        >
                          <TextareaInput
                            name="comments"
                            :value="review.comments"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                    <div class="text-center">
                      <CButton
                        name="job-approval-cancel-btn"
                        type="button"
                        class="
                          action-btn
                          mx-1
                          my-2
                          p-2
                          btn-outline-primary btn-square
                        "
                        @click="navToActivityList"
                        >Cancel</CButton
                      >
                      <CButton
                        name="job-approval-submit-btn"
                        type="button"
                        class="action-btn mx-1 my-2 p-2 btn-primary btn-square"
                        @click="onSubmit"
                        >Submit</CButton
                      >
                    </div>
                  </form>
                </ValidationObserver>
              </CCol>
            </CRow>
            <!-- <CRow>
                  <CCol lg="6"> </CCol>
                  <CCol lg="6" class="pb-2">
                    <CRow>
                      <label
                        name="action_label"
                        class="required col-lg-12 col-md-12"
                        >Select Action</label
                      >
                      <div class="col-lg-4">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="action"
                            :value="review.action"
                            @input="handleChangeSelect"
                            :options="
                              options && options['actions']
                                ? options['actions']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                        <CRow class="mb-4" v-if="openComment">
                          <label class="col-lg-12 col-md-12 required"
                            >Comment</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextareaInput
                                name="comments"
                                :value="review.comments"
                                @input="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                    </CRow>
                  </CCol>
                </CRow> -->
          </div>
        </div>
      </CCardBody>
    </CCard>
    <JobCardPreviewModal
      :isShowPopup="advertModal.isShowPopup"
      :onCancelPreview="advertModalCallBack"
    />
    <Modal
      :modalTitle="previewConfirmModal.modalTitle"
      :modalColor="previewConfirmModal.modalColor"
      :modalContent="previewConfirmModal.modalContent"
      :isShowPopup="previewConfirmModal.isShowPopup"
      :buttons="previewConfirmModal.buttons"
      :modalCallBack="previewConfirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import Select from "@/components/reusable/Fields/Select";
import Modal from "@/components/reusable/Modal";
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Select,
    TextareaInput,
    RadioButton,
    JobCardPreviewModal,
    Modal,
  },
  data() {
    return {
      JobCardDetailView: false,
      JobCardDetailViews: false,
      review: {
        publish: true,
      },
      payload: {},
      openComment: false,
      btnText: "Submit",
      advertModal: {
        isShowPopup: false,
      },
      previewConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "Please Preview the Job",
        isShowPopup: false,
        buttons: ["OK"],
      },
      fields: [
        { key: "date", _style: "width:25%" },
        {
          key: "name",
          _style: "width:25%",
        },
        {
          key: "role",
          _style: "width:10%",
        },
        {
          key: "status",
          _style: "width:15%",
        },
        {
          key: "comment",
          _style: "width:25%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getReviewedJobID",
      "getAuthoriserActions",
      "getCurrency",
    ]),
    options() {
      return {
        actions: this.getAuthoriserActions || [],
      };
    },
    isApproved() {
      return this.review?.status_id?.code != 29 || false;
    },
    activityComments() {
      return (
        this.comments
          // .filter((e) => e.comments)
          .map((v) => ({
            name: v.initiated_by_user_name || "--",
            date: this.formatDate(v.created_on) || "--",
            comment: v?.comments || "--",
            role:
              (v?.initiated_by_user?.roles &&
                v?.initiated_by_user?.roles
                  .map((v) => _.startCase(v.role_type))
                  .join()) ||
              "--",
            status:
              `${v?.status} ${v?.sub_status ? " - " + v?.sub_status : ""}` ||
              "--",
          })) || []
      );
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchActivity",
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchAuthoriserActions",
    ]),
    handleInput(name, value) {
      Vue.set(this.review, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      Vue.set(this.review, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.review, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code : null,
      };
      if (name === "action") {
        this.openComment = !this.payload[name];
      }
      if (name == "status_id" && value?.code == 29) {
        this.handleInput("comments", "Approved");
      } else {
        this.handleInput("comments", "");
      }
    },

    navToActivityList() {
      this.$store.commit("SET_ACTIVE_TAB", 0);
      this.$router.push({ path: `/list-activity` });
    },
    async onSubmit() {
      // if (!this.getReviewedJobID.includes(this.activityDetails?.job_id)) {
      //   this.previewConfirmModal.isShowPopup = true;
      //   return;
      // }
      if (this.JobCardDetailView == false) {
        this.previewConfirmModal.isShowPopup = true;
        return;
      }
      const isValid = await this.$refs.reviewCandidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      if (this.review?.status_id?.code === 29 && !this.review?.comments) {
        this.handleInput("comments", "");
      }
      let finalPayload = {
        job_id: this.activityDetails?.job_id,
        comment: this.review?.comments,
        customer_id: this.activityDetails?.customer_uid,
        action_detail_id: this.activityDetails?.action_detail_id,
        organisation_uid: this.activityDetails?.organisation_id,
        status_id: this.review?.status_id?.code,
        status_name: this.review?.status_id?.label,
      };
      this.$emit("changeActivityStatus", finalPayload);
    },
    navToJobDetails(job_id) {
      this.$store.commit("SET_REVIEWED_JOBID", job_id);
      this.JobCardDetailView = true;
      // let fullPath = this.$route.fullPath;
      // this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      // this.$router.push({ path: `/job-list-preview/${job_id}` });
      // let routeData = this.$router.resolve({
      //   path: `/job-list-preview/${job_id}`,
      // });
      // window.open(routeData.href, "_blank");
      this.$emit("openJobInfoModal", job_id);
    },
    openJobAdvert(job_id) {
      this.JobCardDetailView = true;
      // this.Functionvalue();
      this.$store.commit("SET_REVIEWED_JOBID", job_id);
      this.fetchJobBenefitsAdvertDescByJobId({
        job_id,
        customer_id: this.getCustomerId,
      }).then((res) => {
        this.advertModal.isShowPopup = true;
      });
    },
    // Functionvalue() {
    // this.JobCardDetailView = true;
    // console.log(this.JobCardDetailView);
    // },
    advertModalCallBack() {
      this.advertModal.isShowPopup = false;
    },
    previewConfirmModalCallBack() {
      this.previewConfirmModal.isShowPopup = false;
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    salaryRange(data) {
      if (data?.job?.salary_min && data?.job?.salary_max)
        return `${data?.job?.salary_min_text || data?.job?.salary_min} - ${
          data?.job?.salary_max_text || data?.job?.salary_max
        } ${this.currencyName(data.job.salary_currency_id)}`;
      return null;
    },
    currencyName(currency_id) {
      if (currency_id)
        return _.result(
          _.find(this.getCurrency, { code: currency_id }),
          "label"
        );
      return "";
    },
  },
  filters: {
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    remainingDays(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const days = alertDate.diff(todaysdate, "days");
        return days >= 0
          ? `${days} Day${days > 1 ? "s" : ""}`
          : "Already Expired";
      }
      return "--";
    },
  },
  mounted() {
    this.fetchAuthoriserActions().then((res) => {
      this.handleChangeSelect("status_id", { code: 29, label: "Approved" });
    });
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
.center {
  display: flex;
  justify-content: center;
}
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
.btn {
  width: 125px;
}
</style>
